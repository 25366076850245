import { z } from 'zod'
import { LoginSchema } from './login.schema'

export enum UserRole {
    USER = 'user',
    ADMIN = 'admin',
    SUPERADMIN = 'superadmin',
}

export const UserSchema = z.object({
    id: z.string().nonempty(),
    role: z.nativeEnum(UserRole),
    name: z.string().nonempty(),
    ...LoginSchema.shape,
})

export type UserSchemaType = z.infer<typeof UserSchema>

export const NewUserSchema = UserSchema.omit({ id: true })
export type NewUserSchemaType = z.infer<typeof NewUserSchema>
