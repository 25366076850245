import { defaultIconProps } from 'config/constants'
import { Check } from 'lucide-react'
import { forwardRef, memo, useCallback, useState } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs'

interface CodeSnippetProps extends React.ComponentProps<typeof SyntaxHighlighter> {
    children: string
    label?: string
}
export const CodeSnippet = memo(
    forwardRef<React.ComponentRef<typeof SyntaxHighlighter>, CodeSnippetProps>(({ label = 'Code', children, ...props }, ref) => {
        const [copy, setCopy] = useState<boolean>(false)

        const copyToClipboard = useCallback<() => void>(() => {
            navigator.clipboard.writeText(children)
            setCopy(true)
            setTimeout(() => setCopy(false), 2000)
        }, [children])

        return (
            <div className="">
                <div className="flex items-center justify-between px-4 py-2 text-sm">
                    <p>{label}</p>
                    <button onClick={copyToClipboard} className="inline-flex items-center gap-1 px-1">
                        {copy ? (
                            <Check {...defaultIconProps} className="animate-in fade-in-0 transition-all duration-300" />
                        ) : (
                            <span className="rounded-md border border-zinc-700 px-2 py-1 transition-all duration-300">Copy</span>
                        )}
                    </button>
                </div>
                <SyntaxHighlighter ref={ref} style={github} customStyle={{}} {...props}>
                    {children}
                </SyntaxHighlighter>
            </div>
        )
    }),
)
CodeSnippet.displayName = 'CodeSnippet'
