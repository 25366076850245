import { InlineCode } from 'components/ui/code'
import { toast } from 'components/ui/use-toast'

export const toastConfig = {
    login: {
        success: (name: string) =>
            toast({
                title: 'Login successful',
                description: (
                    <>
                        Welcome, <InlineCode>{name}</InlineCode>
                    </>
                ),
                variant: 'success',
            }),
        error: () =>
            toast({
                title: 'Failed to login',
                description: 'Please try again later',
                variant: 'destructive',
            }),
        successWithEmail: (email: string) =>
            toast({
                title: 'First step is done!',
                description: (
                    <>
                        Check your email <InlineCode>{email}</InlineCode> to confirm it. If you don't see the letter, check the
                        spam folder
                    </>
                ),
                variant: 'success',
            }),
    },

    register: {
        success: (name: string) =>
            toast({
                title: 'Register successful',
                description: (
                    <>
                        Welcome, <InlineCode>{name}</InlineCode>
                    </>
                ),
                variant: 'success',
            }),
        error: () =>
            toast({
                title: 'Failed to register',
                description: 'Please try again later',
                variant: 'destructive',
            }),
    },

    reset: {
        resetWithEmail: (email: string) =>
            toast({
                title: 'First step is done!',
                description: (
                    <>
                        Please check your email <InlineCode>{email}</InlineCode>, we have sent you further instructions.
                    </>
                ),
                variant: 'success',
            }),
        error: () =>
            toast({
                title: 'Failed to reset',
                description: 'Please try again later',
                variant: 'destructive',
            }),
    },

    chat: {
        delete: {
            success: () =>
                toast({
                    title: 'Chat deleted',
                    variant: 'destructive',
                }),
            error: () =>
                toast({
                    title: 'Failed to delete chat',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        sendMessage: {
            notAllowed: () =>
                toast({
                    title: 'You are not allowed to send messages here',
                    description: 'Please contact the administrator',
                    variant: 'destructive',
                }),
        },
        sendImage: {
            notImage: () =>
                toast({
                    title: 'File is not an image',
                    description: 'Please select an image',
                    variant: 'destructive',
                }),
        },
    },

    instance: {
        delete: {
            success: () =>
                toast({
                    title: 'Project deleted',
                    variant: 'destructive',
                }),
            error: () =>
                toast({
                    title: 'Failed to delete project',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
            warn: () =>
                toast({
                    title: 'Failed to delete project',
                    description: 'You have chats in this project',
                    variant: 'destructive',
                }),
        },
        update: {
            success: () =>
                toast({
                    title: 'Project updated',
                    description: 'Project updated successfully',
                    variant: 'success',
                }),
            error: () =>
                toast({
                    title: 'Failed to update project',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        updateColors: {
            success: () =>
                toast({
                    title: 'Project updated',
                    description: 'Project colors updated successfully',
                    variant: 'success',
                }),
            error: () =>
                toast({
                    title: 'Failed to update project colors',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
    },

    user: {
        create: {
            success: (email: string) =>
                toast({
                    title: 'User created',
                    description: (
                        <>
                            User with email <InlineCode>{email}</InlineCode> has been created
                        </>
                    ),
                    variant: 'success',
                }),
            error: () =>
                toast({
                    title: 'Failed to create user',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
    },

    unknownError: () =>
        toast({
            title: 'Unknown error',
            description: 'Please try again later',
            variant: 'destructive',
        }),
}
