import { toast } from 'components/ui/use-toast'
import { urlConfig } from 'config/url.config'
import { useState } from 'react'
import { z } from 'zod'
import { passwordSchema } from '../schemas/profile.schema'
import { UserProfile } from './use-get-profile'

export const useUpdatePassword = (data: UserProfile | null) => {
    const [loadingSubmitPassword, setLoading] = useState<boolean>(false)

    async function onSubmitPassword(values: z.infer<typeof passwordSchema>, cb?: () => void) {
        setLoading(true)
        if (values.newPassword !== values.confirmPassword) {
            toast({
                title: 'Failed to update user',
                description: 'Please enter correct passwords',
                variant: 'destructive',
            })
            setLoading(false)
            return
        }

        const resPromise = await fetch(`${urlConfig.API_URL}${urlConfig.api.adminUsers}/${data?.uid}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password: values.newPassword,
            }),
        })
        if (!resPromise.ok) {
            toast({
                title: 'Failed to update user',
                description: 'Error occurred while updating user. Please try again later',
                variant: 'destructive',
            })
            setLoading(false)
            return
        }

        toast({
            title: 'User updated',
            description: 'Password updated successfully',
        })

        setLoading(false)
        cb?.()
    }

    return { loadingSubmitPassword, onSubmitPassword }
}
