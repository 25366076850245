import { SlotItemWithIconReversed } from 'components/slot-item-with-icon'
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuSeparator,
    ContextMenuTrigger,
} from 'components/ui/context-menu'
import { Skeleton } from 'components/ui/skeleton'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { getLocaleTime } from 'config/dayjs.config'
import { firebaseStorage } from 'config/firebase.config'
import { toastConfig } from 'config/toast.config'
import { getBlob, ref } from 'firebase/storage'
import { Edit, Files, Trash2 } from 'lucide-react'
import { ChatFullscreenImagePreview } from 'modules/chat/components/chat-fullscreen-image-preview-modal'
import { useChatStore } from 'modules/chat/store/chat.store'
import { ChatType } from 'modules/chat/types/chat.type'
import { MessageVariant } from 'modules/chat/types/message.type'
import { TextFormSchemaType } from 'modules/chat/types/text-form.schema'
import { MessageEditForm } from 'modules/message/components/message-edit-form'
import { memo, useCallback, useMemo } from 'react'
import { cn } from 'utils/cn'

interface MessageProps extends React.ComponentProps<'div'> {
    chat: ChatType | undefined
    message: ChatType['messages'][number]
    right: boolean
    isLoading?: boolean
    textColor?: string
}
export const Message: React.FC<MessageProps> = memo(({ chat, message, right, isLoading, className, textColor, ...props }) => {
    const updateMessage = useChatStore(state => state.updateMessage)
    const deleteMessage = useChatStore(state => state.deleteMessage)

    const isText = useMemo(() => message.type === MessageVariant.TEXT, [message.type])
    const isImage = useMemo(() => message.type === MessageVariant.IMAGE, [message.type])
    const canBeEdited = useMemo(() => message.type === MessageVariant.TEXT && right, [message.type, right])
    const canBeDeleted = useMemo(() => right, [right])

    const onCopy = useCallback(async () => {
        try {
            if (message.type === MessageVariant.TEXT) {
                navigator.clipboard.writeText(message.content)
                return
            }
            if (message.type === MessageVariant.IMAGE) {
                const imageRef = ref(firebaseStorage, message.content)
                const imageBlob = await getBlob(imageRef)
                navigator.clipboard.write([
                    new ClipboardItem({
                        [imageBlob.type]: imageBlob,
                    }),
                ])
                return
            }
        } catch (error) {
            console.error(error)
            toastConfig.unknownError()
        }
    }, [message.content, message.type])

    const onEdit = useCallback(
        (values: TextFormSchemaType) => {
            try {
                if (!chat) {
                    throw new Error('No chat')
                }
                updateMessage(chat, message, {
                    ...message,
                    content: values.text,
                })
            } catch (error) {
                console.error(error)
                toastConfig.unknownError()
            }
        },
        [chat, message, updateMessage],
    )

    const onDelete = useCallback(() => {
        try {
            if (!chat) {
                throw new Error('No chat')
            }
            deleteMessage(chat, message)
        } catch (error) {
            console.error(error)
            toastConfig.unknownError()
        }
    }, [chat, deleteMessage, message])

    // skeleton loading
    if (isLoading) {
        return (
            <Skeleton key={String(message.createdAt)} className={cn('h-10 w-[40%] rounded-lg', right ? 'ml-auto' : 'bg-muted')}>
                {message.content}
            </Skeleton>
        )
    }

    return (
        <AlertDialog>
            <ContextMenu>
                <ContextMenuTrigger asChild>
                    <div
                        key={String(message.createdAt)}
                        {...props}
                        className={cn(
                            'flex w-max max-w-[70vw] flex-col rounded-lg px-3 py-2 selection:bg-slate-600 selection:text-gray-300 sm:max-w-[60vw] md:max-w-[50vw]',
                            right ? 'bg-message text-message-foreground ml-auto' : 'bg-muted',
                            isImage && 'mb-1 overflow-hidden px-0 pb-0 pt-0',
                            className,
                        )}
                    >
                        {isText ? (
                            <>
                                <span className={cn('break-words text-sm leading-relaxed', right && textColor)}>
                                    {message.content}
                                </span>
                                <span
                                    className={cn(
                                        'text-muted-foreground text-[0.7rem]',
                                        right ? 'text-muted/80 mr-auto' : 'ml-auto',
                                        isImage && 'px-3 pt-1',
                                        right && textColor,
                                    )}
                                >
                                    {getLocaleTime(message.createdAt)}
                                </span>
                            </>
                        ) : (
                            <Tooltip delayDuration={1}>
                                <TooltipTrigger asChild>
                                    <ChatFullscreenImagePreview src={message.content}>
                                        <img
                                            src={message.content}
                                            alt="media-message"
                                            className="max-h-[30vh] max-w-[30vw] rounded-lg border object-contain"
                                        />
                                    </ChatFullscreenImagePreview>
                                </TooltipTrigger>
                                <TooltipContent side={right ? 'left' : 'right'} className="bottom-0 text-xs">
                                   {/* <span className={cn('text-muted-foreground text-[0.7rem]absolute pt-[23%] py-2 pb-1',
                                    right ? 'pl-[29%]' : 'pl-[60%]'
                                   )}> */}
                                    {getLocaleTime(message.createdAt)}
                                   {/* </span> */}
                                </TooltipContent>
                            </Tooltip>
                        )}
                    </div>
                </ContextMenuTrigger>
                <ContextMenuContent>
                    <ContextMenuItem onSelect={onCopy} className="group">
                        <SlotItemWithIconReversed Icon={Files}>Copy</SlotItemWithIconReversed>
                    </ContextMenuItem>
                    {canBeEdited && (
                        <AlertDialogTrigger asChild>
                            <ContextMenuItem>
                                <SlotItemWithIconReversed Icon={Edit}>Edit</SlotItemWithIconReversed>
                            </ContextMenuItem>
                        </AlertDialogTrigger>
                    )}
                    {canBeDeleted && (
                        <>
                            <ContextMenuSeparator />
                            <ContextMenuItem onSelect={onDelete} className="text-destructive focus:text-destructive">
                                <SlotItemWithIconReversed Icon={Trash2}>Delete</SlotItemWithIconReversed>
                            </ContextMenuItem>
                        </>
                    )}
                </ContextMenuContent>
            </ContextMenu>

            {/* edit message dialog */}
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Edit message</AlertDialogTitle>
                    <AlertDialogDescription>Type your new message below save it.</AlertDialogDescription>
                </AlertDialogHeader>
                <MessageEditForm messageContent={message.content} onSubmit={onEdit} />
            </AlertDialogContent>
        </AlertDialog>
    )
})
Message.displayName = Message.name
