/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api'
import { mapConfig } from 'modules/map/constants/map.constants'
import { cn } from 'utils/cn'

interface MapProps extends React.ComponentProps<'div'> {
    lat?: number
    lng?: number
    zoom?: number
    innerClassName?: string
}

const containerStyle = {
    width: '100%',
    height: '100%',
}

export const Map: React.FC<React.PropsWithChildren<MapProps>> = memo(
    ({
        lat = mapConfig.defaultLat,
        lng = mapConfig.defaultLng,
        zoom = mapConfig.defaultZoom,
        innerClassName,
        className,
        children,
        ...props
    }) => {
        const { id } = useParams()
        const [map, setMap] = useState<google.maps.Map | null>(null);
        const { isLoaded, loadError } = useLoadScript({
            googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || 'YOUR_API_KEY_HERE',
        })

        const mapRef = useRef<google.maps.Map | null>(null)

        const initialCenter = useMemo(
            () => ({
                lat: lat,
                lng: lng,
            }),
            [lat, lng],
        )

        const handleLoad = useCallback((mapInstance: google.maps.Map) => {
            mapRef.current = mapInstance
        }, [])

        const onLoad = useCallback(
            function callback(map: any) {
            //   const bounds = new window.google.maps.LatLngBounds({lat, lng});
            //   bounds.extend({lat, lng});
        
            //   map.fitBounds(bounds);
        
              setMap(map);
            },
            [setMap]
          );
        
          const onUnmount = useCallback(function callback(map: any) {
            setMap(null);
          }, []);
       
        useEffect(() => {
            if (mapRef.current) {
                mapRef.current.panTo({ lat, lng })
            }
        }, [lat, lng, id])

        if (!isLoaded) return <div>Loading...</div>
        if (loadError) return <div>Error loading maps</div>

        return (
            <div {...props} className={cn('relative h-full w-full', className)}>
                <div className={cn('absolute h-full w-full', innerClassName)}>
                <GoogleMap
          mapContainerStyle={containerStyle}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          zoom={40}
          center={{lat, lng}}
          onLoad={onLoad}
          onUnmount={onUnmount}
          
        >
{children}
                    </GoogleMap>
                </div>
            </div>
        )
    },
)
Map.displayName = Map.name