import { BackButton } from 'components/back-button'
import { Card, CardContent, CardTitle } from 'components/ui/card'
import { DropdownMenuItem } from 'components/ui/dropdown-menu'
import { Skeleton } from 'components/ui/skeleton'
import { ChatAvatar } from 'modules/chat/components/chat-avatar'
import { ChatType } from 'modules/chat/types/chat.type'
import { useUserStore } from 'modules/user/store/user.store'
import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn'

interface ChatHeaderProps extends React.ComponentProps<'div'> {
    chat: ChatType | undefined
    isClientPage?: boolean
    isLoading?: boolean
    children?: React.ReactElement<typeof DropdownMenuItem> | React.ReactElement<typeof DropdownMenuItem>[]
    textColor?: string
}
export const ChatHeader: React.FC<ChatHeaderProps> = memo(
    ({ chat, isClientPage = false, isLoading, className, textColor, children, ...props }) => {
        const navigate = useNavigate()
        const toggleSidebar = useUserStore(state => state.toggleSidebar)

        const onBackClick = useCallback(() => {
            navigate(-1)
        }, [navigate])

        // skeleton loading
        if (isLoading) {
            return (
                <div {...props} className={cn('absolute z-30 w-full p-2 pl-0', className)}>
                    <Card>
                        <CardContent className="flex items-center justify-between gap-3 py-2">
                            {isClientPage && <Skeleton className="h-9 w-16 rounded-md px-3" />}
                            <Skeleton className="h-9 w-[50%] sm:w-[30%]" />
                            <Skeleton className="h-10 w-10 rounded-full" />
                        </CardContent>
                    </Card>
                </div>
            )
        }

        return (
            <>
                {/* <div className="from-background absolute top-0 z-20 h-16 w-full bg-gradient-to-b" /> */}
                <div
                    {...props}
                    className={cn('absolute z-30 w-full p-2 pl-0 selection:bg-slate-600 selection:text-gray-300', className)}
                >
                    <Card className="bg-chatheader border-none">
                        <CardContent className="flex items-center gap-[40%] py-2">
                            {!isClientPage && <BackButton variant="ghost" size="sm" asChild onClick={onBackClick} />}
                            <CardTitle
                                onClick={toggleSidebar}
                                className={cn(
                                    'text-muted-foreground w-fit  cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 text-lg',
                                    textColor,
                                )}
                            >
                                {isClientPage ? 'Chat' : chat?.username}
                            </CardTitle>
                            {/* <ChatAvatar fallback={chat?.username} onClick={toggleSidebar} className="cursor-pointer" /> */}
                        </CardContent>
                    </Card>
                </div>
            </>
        )
    },
)
ChatHeader.displayName = ChatHeader.name
