import { toast } from 'components/ui/use-toast'
import { firebaseAuth } from 'config/firebase.config'
import { sendEmailVerification } from 'firebase/auth'
import { useState } from 'react'

export const useVerifyEmail = () => {
    const [loadingVerifying, setLoading] = useState<boolean>(false)

    async function verifyEmail(cb: () => void) {
        setLoading(true)

        const user = firebaseAuth.currentUser
        if (user) {
            sendEmailVerification(user)
                .then(() => {
                    toast({
                        title: 'Email sent',
                        description: 'Verification email sent. Please check your inbox.',
                    })
                })
                .catch(error => {
                    toast({
                        title: 'Failed to send email',
                        description: 'Error occurred while sending verification email. Please try again later.',
                        variant: 'destructive',
                    })
                })
        }

        cb?.()

        setLoading(false)
    }

    return { loadingVerifying, verifyEmail }
}
