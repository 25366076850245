import { toast } from 'components/ui/use-toast'
import { urlConfig } from 'config/url.config'
import { ChatType } from 'modules/chat/types/chat.type'
import { useEffect, useState } from 'react'
import { StatsSchema, UserStatistics, UserStats } from '../types/instance.type'

export const useGetUserStatistics = (ownerId: string | undefined) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<StatsSchema[] | null>(null)
    const [userStats, setUserStats] = useState<UserStats | null>(null)

    const fetchData = async (id: string) => {
        try {
            const res = await fetch(`${urlConfig.API_URL}${urlConfig.api.userStat}/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!res.ok) {
                throw new Error('Failed to fetch chats')
            }

            const responseData = (await res.json()) as { message: string; data: UserStatistics }

            if (!responseData.data) {
                throw new Error('Failed to fetch chats data')
            }

            setData(responseData.data.instancesStats)
            setUserStats(responseData.data.userStats)
        } catch (error) {
            toast({
                title: 'Failed to fetch statistics',
                description: 'An error occurred while fetching statistics. Please try again later.',
                variant: 'destructive',
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (ownerId) {
            fetchData(ownerId)
        }
    }, [ownerId])

    const refetchData = () => {
        setLoading(true)
        if (ownerId) {
            fetchData(ownerId)
        }
    }

    return { loading, data, userStats, refetchData }
}
