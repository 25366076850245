import emojiData from '@emoji-mart/data'
import EmojiPicker from '@emoji-mart/react'
import { Button } from 'components/ui/button'
import { Card, CardContent } from 'components/ui/card'
import { Form, FormControl, FormField, FormItem } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { Skeleton } from 'components/ui/skeleton'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip'
import { defaultIconProps } from 'config/constants'
import { Paperclip, SendHorizonal, Smile } from 'lucide-react'
import { ChatSendImageModal } from 'modules/chat/components/chat-send-image-modal'
import { EmojiType } from 'modules/chat/types/emoji.type'
import { FileFormSchemaType } from 'modules/chat/types/file-form.schema'
import { TextFormSchemaType } from 'modules/chat/types/text-form.schema'
import { useThemeStore } from 'modules/theme/store/theme.store'
import { ThemeType } from 'modules/theme/type/theme.type'
import { getSystemTheme } from 'modules/theme/utils/get-system-theme'
import { useUserStore } from 'modules/user/store/user.store'
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { cn } from 'utils/cn'

interface ChatFooterProps extends Omit<React.ComponentProps<'div'>, 'onSubmit'> {
    textForm: UseFormReturn<TextFormSchemaType>
    onTextSubmit: SubmitHandler<TextFormSchemaType>
    fileForm: UseFormReturn<FileFormSchemaType>
    onFileFormSubmit: SubmitHandler<FileFormSchemaType>
    isLoading?: boolean
    archived?: boolean
    textColor?: string
    textFooterColor?: string
}
export const ChatFooter = memo(
    forwardRef<React.ComponentRef<typeof Input>, ChatFooterProps>(
        (
            {
                textForm,
                onTextSubmit,
                fileForm,
                onFileFormSubmit,
                isLoading,
                archived = false,
                className,
                children,
                textColor,
                textFooterColor,
                ...props
            },
            ref,
        ) => {
            const theme = useThemeStore(state => state.theme)

            const [isDisabled, setIsDisabled] = useState(true)
        
            const emojiPickerTheme = useMemo(() => (theme === ThemeType.Enum.SYSTEM ? getSystemTheme() : theme), [theme])

            const onEmojiSelect = useCallback(
                (emoji: EmojiType) => {
                    const prevValue = textForm.getValues('text')
                    textForm.setValue('text', prevValue + emoji.native)
                },
                [textForm],
            )

            // change button disabled state on form state change
            useEffect(() => {
                textForm.watch(data => {
                    setIsDisabled(textForm.formState.isSubmitting || !textForm.formState.isValid || !data.text)
                })
            }, [textForm])

            // skeleton loading
            if (isLoading) {
                return (
                    <div {...props} className={cn('absolute bottom-0 z-30 w-full p-2 pl-0', className)}>
                        <Card>
                            <CardContent className="flex w-full items-center gap-5 py-2">
                                <Skeleton className="h-8 flex-1" />
                                <Skeleton className="h-8 w-10 rounded-full" />
                            </CardContent>
                        </Card>
                    </div>
                )
            }

            return (
                <>
                    {/* <div className="from-background absolute bottom-0 z-20 h-16 w-full bg-gradient-to-t" /> */}
                    <div
                        {...props}
                        className={cn(
                            'absolute bottom-0 w-full z-30 p-2 pl-0 selection:bg-slate-600 selection:text-gray-300',
                            archived && 'pointer-events-none opacity-50',
                           
                            className,
                        )}
                    >
                        <Card className={cn('bg-chatfooter border-none', textFooterColor)}>
                            <CardContent className="flex w-full items-center gap-1 py-0">
                                {/* send files */}
                                <TooltipProvider>
                                    <Tooltip delayDuration={1500}>
                                        <TooltipTrigger asChild>
                                            <ChatSendImageModal
                                                textColor={textColor}
                                                asChild
                                                form={fileForm}
                                                onSubmit={onFileFormSubmit}
                                            >
                                                <Button
                                                    variant="none"
                                                    size="icon"
                                                    className={cn('text-muted-foreground', textFooterColor)}
                                                >
                                                    <Paperclip {...defaultIconProps} />
                                                </Button>
                                            </ChatSendImageModal>
                                        </TooltipTrigger>
                                        <TooltipContent>Select media file</TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>

                                {/* message input */}
                                <Form {...textForm}>
                                    <form
                                        onSubmit={textForm.handleSubmit(onTextSubmit)}
                                        autoComplete="off"
                                        className="flex w-full items-center gap-1"
                                    >
                                        <FormField
                                            name="text"
                                            control={textForm.control}
                                            render={({ field }) => (
                                                <FormItem className="w-full">
                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            ref={ref}
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder="Write a message..."
                                                            className={cn(
                                                                'rounded-none border-none px-0 py-6 focus:border-none focus:outline-none focus:ring-0 focus:ring-offset-0 focus-visible:border-none focus-visible:ring-0 focus-visible:ring-offset-0 active:outline-none active:ring-0 active:ring-offset-0',
                                                                textFooterColor,
                                                                textFooterColor === 'text-black'
                                                                    ? 'placeholder:text-black'
                                                                    : 'placeholder:text-white',
                                                            )}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />

                                        {/* emoji select */}
                                        <TooltipProvider>
                                            <Tooltip delayDuration={1500}>
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <TooltipTrigger asChild>
                                                            <Button
                                                                variant="none"
                                                                size="icon"
                                                                className={cn('text-muted-foreground', textFooterColor)}
                                                            >
                                                                <Smile {...defaultIconProps} />
                                                            </Button>
                                                        </TooltipTrigger>
                                                    </PopoverTrigger>
                                                    <PopoverContent align="end" className="border-none bg-none p-0 shadow-none">
                                                        <EmojiPicker
                                                            theme={emojiPickerTheme}
                                                            data={emojiData}
                                                            onEmojiSelect={onEmojiSelect}
                                                            className={cn('border')}
                                                        />
                                                    </PopoverContent>
                                                </Popover>
                                                <TooltipContent>Select emoji</TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>

                                        {/* send message */}
                                        <TooltipProvider>
                                            <Tooltip delayDuration={1500}>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        variant="none"
                                                        size="icon"
                                                        type="submit"
                                                        disabled={isDisabled}
                                                        className={cn(
                                                            'text-muted-foreground',
                                                            isDisabled && 'cursor-not-allowed',
                                                            textFooterColor,
                                                        )}
                                                    >
                                                        <SendHorizonal
                                                            {...defaultIconProps}
                                                            className={cn(
                                                                'transition-all duration-300',
                                                                !isDisabled && '-translate-y-[0.1875rem] -rotate-[30deg]',
                                                            )}
                                                        />
                                                    </Button>
                                                </TooltipTrigger>
                                                <TooltipContent>Send message</TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </form>
                                </Form>
                            </CardContent>
                        </Card>
                    </div>
                </>
            )
        },
    ),
)
ChatFooter.displayName = ChatFooter.name
