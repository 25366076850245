import { getCurrentISODate } from 'config/dayjs.config'
import { dbBannedUsers, dbChats, deleteImageFromStorage } from 'config/firebase.config'
import { where } from 'firebase/firestore'
import _ from 'lodash'
import { ChatType } from 'modules/chat/types/chat.type'
import { MessageRole, MessageType, MessageVariant } from 'modules/chat/types/message.type'
import { BannedUserFieldsEnum, BannedUserType } from 'modules/user/types/banned-user.type'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

interface UseChatStore {
    chats: ChatType[]
    archivedChats: ChatType[]
    bannedChats: ChatType[]
    clientChatId: ChatType['id']
    isAllLoading: boolean
    isIdLoading: boolean
    //
    setClientChatId: (id: ChatType['id']) => void
    //
    fetchChats: (id: string) => Promise<void>
    fetchChatById: (id: string) => Promise<ChatType | undefined>
    createChat: () => Promise<ChatType>
    updateChat: (chat: ChatType) => Promise<ChatType>
    deleteChat: (id: ChatType['id']) => Promise<void>
    archiveChat: (chat: ChatType, archived: boolean) => Promise<void>
    toggleChatUnread: (chat: ChatType) => Promise<void>
    //
    sendMessage: (
        chat: Pick<ChatType, 'id' | 'messages' | 'isUnread' | 'isAdminUnread'>,
        newMessage: MessageType,
    ) => Promise<void>
    updateMessage: (chat: ChatType, oldMessage: MessageType, newMessage: MessageType) => Promise<void>
    deleteMessage: (chat: ChatType, message: MessageType) => Promise<void>
    //
    setAllChats: (chats: ChatType[]) => void
    setChatById: (chat: ChatType) => void
    //
    ban: (params: { chat: ChatType; reason: BannedUserType['reason']; bannedBy: BannedUserType['bannedBy'] }) => Promise<void>
    unban: (chat: ChatType) => Promise<void>
    checkBan: (chat: ChatType) => Promise<boolean>
}

export const useChatStore = create<UseChatStore, [['zustand/devtools', UseChatStore], ['zustand/immer', UseChatStore]]>(
    devtools(
        immer((set, get) => ({
            chats: [] as ChatType[],
            archivedChats: [] as ChatType[],
            bannedChats: [] as ChatType[],
            clientChatId: '',
            isAllLoading: false as boolean,
            isIdLoading: false as boolean,

            //

            setClientChatId: id => {
                set(state => {
                    state.clientChatId = id
                })
            },

            //

            fetchChats: async id => {
                set(state => {
                    state.isAllLoading = true
                    state.chats = []
                    state.archivedChats = []
                    state.bannedChats = []
                })
                try {
                    const setAllChats = get().setAllChats
                    const allChats = await dbChats.query(where('instanceId', '==', id))
                    setAllChats(allChats)
                } catch (error) {
                    console.error(error)
                } finally {
                    set(state => {
                        state.isAllLoading = false
                    })
                }
            },

            //

            fetchChatById: async id => {
                set(state => {
                    state.isIdLoading = true
                })
                try {
                    const fetchedChat = await dbChats.getById(id)
                    if (!fetchedChat) {
                        throw new Error('Chat not found')
                    }
                    set(state => {
                        state.chats = state.chats.map(stateChat => {
                            if (stateChat.id !== id) {
                                return stateChat
                            }
                            return fetchedChat
                        })
                    })
                    return fetchedChat
                } catch (error) {
                    console.error(error)
                } finally {
                    set(state => {
                        state.isIdLoading = false
                    })
                }
            },

            //

            createChat: async () => {
                const newChat = await dbChats.create({
                    createdAt: getCurrentISODate(),
                    //
                    isUnread: false,
                    isAdminUnread: false,
                    isArchived: false,
                    isBanned: false,
                    //
                    banInfo: null,
                    location: {
                        lat: 0,
                        lng: 0,
                        city: '',
                        country: '',
                    },
                    ip: '',
                    username: '',
                    messages: [],
                    instanceId: '',
                })
                set(state => {
                    state.chats = [...state.chats, newChat]
                })
                return newChat
            },

            //

            updateChat: async chat => {
                const setChatById = get().setChatById
                setChatById(chat)
                await dbChats.update(chat)
                return chat
            },

            //

            deleteChat: async id => {
                const state = get()
                const chat = [...state.chats, ...state.archivedChats, ...state.bannedChats].find(chat => chat.id === id)
                chat?.messages?.forEach(message => {
                    if (message.type === MessageVariant.IMAGE) {
                        deleteImageFromStorage(message.content)
                    }
                })
                set(state => {
                    state.chats = state.chats.filter(chat => chat.id !== id)
                    state.archivedChats = state.archivedChats.filter(chat => chat.id !== id)
                    state.bannedChats = state.bannedChats.filter(chat => chat.id !== id)
                })
                await dbChats.delete(id)
            },

            //

            archiveChat: async (chat, archived) => {
                const updateChat = get().updateChat
                const setAllChats = get().setAllChats
                await updateChat({
                    ...chat,
                    isArchived: !archived,
                })
                setAllChats([chat])
            },

            //

            toggleChatUnread: async chat => {
                const updateChat = get().updateChat
                await updateChat({
                    ...chat,
                    isUnread: !chat.isUnread,
                })
            },

            //

            sendMessage: async (chat, newMessage) => {
                await dbChats.update({
                    id: chat.id,
                    messages: [...chat.messages, newMessage],
                    isUnread: newMessage.senderRole !== MessageRole.ADMIN,
                    isAdminUnread: newMessage.senderRole === MessageRole.ADMIN,
                })
            },

            //

            updateMessage: async (chat, oldMessage, newMessage) => {
                await dbChats.update({
                    id: chat.id,
                    messages: chat.messages.map(message => {
                        if (message.createdAt === oldMessage.createdAt && message.content === oldMessage.content) {
                            return newMessage
                        }
                        return message
                    }),
                })
            },

            //

            deleteMessage: async (chat, message) => {
                if (message.type === MessageVariant.IMAGE) {
                    deleteImageFromStorage(message.content)
                }
                await dbChats.update({
                    id: chat.id,
                    messages: chat.messages.filter(
                        filterMessage =>
                            !(filterMessage.createdAt === message.createdAt && filterMessage.content === message.content),
                    ),
                })
            },

            //

            setAllChats: chats => {
                const chatsWithoutDuplicates = _.uniqBy(
                    [...get().chats, ...get().archivedChats, ...get().bannedChats, ...chats],
                    data => data.id,
                )
                set(state => {
                    state.chats = chatsWithoutDuplicates.filter(chat => !chat.isArchived && !chat.isBanned)
                    state.archivedChats = chatsWithoutDuplicates.filter(chat => chat.isArchived)
                    state.bannedChats = chatsWithoutDuplicates.filter(chat => chat.isBanned && !chat.isArchived)
                })
            },

            //

            setChatById: chat => {
                set(state => {
                    state.chats = state.chats.map(stateChat => {
                        if (stateChat.id !== chat.id) {
                            return stateChat
                        }
                        return chat
                    })
                    state.archivedChats = state.archivedChats.map(stateChat => {
                        if (stateChat.id !== chat.id) {
                            return stateChat
                        }
                        return chat
                    })
                    state.bannedChats = state.bannedChats.map(stateChat => {
                        if (stateChat.id !== chat.id) {
                            return stateChat
                        }
                        return chat
                    })
                })
            },

            //

            ban: async ({ chat, reason, bannedBy }) => {
                const state = get()
                const updateChat = state.updateChat
                const bannedChatsByIp = [...state.chats, ...state.archivedChats, ...state.bannedChats].filter(
                    arrChat => arrChat.ip === chat.ip,
                )
                const newBanItem = {
                    reason,
                    bannedBy,
                    bannedAt: getCurrentISODate(),
                }
                Promise.all([
                    await updateChat({
                        ...chat,
                        isBanned: true,
                        banInfo: newBanItem,
                    }),
                    await dbBannedUsers.create({
                        ip: chat.ip,
                        ...newBanItem,
                    }),
                    ...bannedChatsByIp.map(async bannedChat => {
                        await updateChat({
                            ...bannedChat,
                            isBanned: true,
                            banInfo: newBanItem,
                        })
                    }),
                ])
            },

            //

            unban: async chat => {
                const state = get()
                const updateChat = state.updateChat
                const bannedChatsByIp = [...state.chats, ...state.archivedChats, ...state.bannedChats].filter(
                    arrChat => arrChat.ip === chat.ip,
                )
                const bannedUsersFromDb = await dbBannedUsers.query(where(BannedUserFieldsEnum.IP, '==', chat?.ip))
                await Promise.all([
                    await updateChat({
                        ...chat,
                        isBanned: false,
                        banInfo: null,
                    }),
                    ...bannedChatsByIp.map(async bannedChat => {
                        await updateChat({
                            ...bannedChat,
                            isBanned: false,
                            banInfo: null,
                        })
                    }),
                    ...bannedUsersFromDb.map(bannedUser => dbBannedUsers.delete(bannedUser.id)),
                ])
            },

            //

            checkBan: async chat => {
                const updateChat = get().updateChat
                if (chat?.isBanned) {
                    return chat.isBanned
                }
                const bannedUsers = await dbBannedUsers.query(where(BannedUserFieldsEnum.IP, '==', chat?.ip))
                await updateChat({
                    ...chat,
                    isBanned: bannedUsers.length > 0,
                    banInfo: bannedUsers[0]
                        ? {
                              bannedAt: bannedUsers[0]?.bannedAt,
                              bannedBy: bannedUsers[0]?.bannedBy,
                              reason: bannedUsers[0]?.reason,
                          }
                        : null,
                })
                return bannedUsers.length > 0
            },
        })),
        {
            name: 'chat',
        },
    ),
)
