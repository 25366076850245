import { ENV, dotenv } from 'config/dotenv.config'

export const urlConfig = {
    API_URL: dotenv.get(ENV.API_URL),
    pages: {
        main: '/',
        chatId: '/chat/:id',
        instance: '/instance/:id',
        client: '/client',
        login: '/login',
        register: '/register',
        reset: '/reset',
        'all-login': '/all-login',
        admin: '/admin',
        statistics: '/statistics',
        customize: 'customize-colors',
        icon: 'customize-icon',
        profile: '/profile',
        notFound: '/404',
    },
    api: {
        login: '/auth/login',
        user: '/user',
        gpt: '/gpt',
        userCoordinates: '/user/coordinates',
        adminUsers: '/user/admin',
        chats: '/chats',
        profile: '/user/profile',
        instanceChats: '/chats/instance',
        userStat: '/chats/statistics',
        clientScript: '/chats/chatScript/:instanceId',
        newUserReg: '/email/registration'
    },
}
