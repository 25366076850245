import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingIcon } from 'components/loading-icon'
import { Alert, AlertDescription, AlertTitle } from 'components/ui/alert'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Switch } from 'components/ui/switch'
import { toast } from 'components/ui/use-toast'
import { deleteImageFromStorage, firebaseStorageDirectories, uploadImageToStorage } from 'config/firebase.config'
import { toastConfig } from 'config/toast.config'
import { urlConfig } from 'config/url.config'
import { AlertCircle, CircleUserRound } from 'lucide-react'
import { FileFormSchemaType } from 'modules/chat/types/file-form.schema'
import { useFileForm } from 'modules/chat/types/use-file-form'
import { useDeleteProfileImage } from 'modules/profile/hooks/use-delete-profile-image'
import { useGetUserProfile } from 'modules/profile/hooks/use-get-profile'
import { useUpdatePassword } from 'modules/profile/hooks/use-update-password'
import { useUpdateProfileInfo } from 'modules/profile/hooks/use-update-profile-info'
import { useVerifyEmail } from 'modules/profile/hooks/use-verify-email'
import { passwordSchema, profileSchema } from 'modules/profile/schemas/profile.schema'
import { UpdateProfileImageModal } from 'modules/user/components/change-profile-image-modal'
import { useUserStore } from 'modules/user/store/user.store'
import { memo, useCallback, useEffect } from 'react'
import { useAuthUser, useSignOut } from 'react-auth-kit'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

const Profile = memo(() => {
    const auth = useAuthUser()()

    const { data, loading, refetchData } = useGetUserProfile(auth?.id)
    const { loadingSubmitProfile, onSubmitProfile } = useUpdateProfileInfo(data)
    const { loadingSubmitPassword, onSubmitPassword } = useUpdatePassword(data)
    const { loadingSubmitDelete, onDeleteImage } = useDeleteProfileImage(data)
    const { loadingVerifying, verifyEmail } = useVerifyEmail()

    const notifications = useUserStore(state => state.notifications)
    const setNotifications = useUserStore(state => state.setNotifications)
    const signOut = useSignOut()

    const onSignOut = useCallback(() => {
        signOut()
    }, [signOut])

    const fileForm = useFileForm()

    const profileForm = useForm<z.infer<typeof profileSchema>>({
        resolver: zodResolver(profileSchema),
        defaultValues: {
            username: '',
            email: '',
        },
    })
    const passwordForm = useForm<z.infer<typeof passwordSchema>>({
        resolver: zodResolver(passwordSchema),
        defaultValues: {
            newPassword: '',
            confirmPassword: '',
        },
    })

    const submitProfile = (values: z.infer<typeof profileSchema>) => {
        onSubmitProfile(values, refetchData)
    }

    const submitPassword = (values: z.infer<typeof passwordSchema>) => {
        const cb = () => {
            refetchData()
            passwordForm.reset()
        }
        onSubmitPassword(values, cb)
    }

    const onSendFile = useCallback(
        async (values: FileFormSchemaType) => {
            try {
                if (!values) {
                    throw new Error('Values are not defined')
                }

                if (data?.image) {
                    await deleteImageFromStorage(data?.image)
                }

                const imageUrl = await uploadImageToStorage(
                    firebaseStorageDirectories.userMedia,
                    values.file,
                    `${auth?.id}_${new Date().getTime()}.png`,
                )

                const resPromise = await fetch(`${urlConfig.API_URL}${urlConfig.api.adminUsers}/${data?.uid}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        image: imageUrl,
                    }),
                })
                if (!resPromise.ok) {
                    toast({
                        title: 'Failed to update user',
                        description: <>Error occurred while updating user. Please try again later</>,
                        variant: 'destructive',
                    })
                    return
                }

                toast({
                    title: 'User updated',
                    description: <>Image updated successfully</>,
                })
                fileForm.reset()
                refetchData()
            } catch (error) {
                console.error(error)
                toastConfig.unknownError()
            }
        },
        [auth?.id, data?.image, data?.uid, fileForm, refetchData],
    )

    useEffect(() => {
        if (data) {
            profileForm.setValue('username', data?.name)
            profileForm.setValue('email', data?.email)
        }
    }, [data, profileForm])

    if (loading) {
        return (
            <div className="flex h-96 w-screen items-center justify-center">
                <LoadingIcon loading={loading} className="mr-2" />
            </div>
        )
    }

    return (
        <div className="container flex flex-col items-start gap-3 md:flex-row">
            <div className="flex w-full flex-col gap-4 md:w-1/3">
                <Card className="flex w-full flex-col items-center gap-3 p-5 ">
                    {data?.image ? (
                        <img
                            src={data?.image}
                            alt="User photo"
                            decoding="async"
                            className="size-28 rounded-xl border object-cover object-center"
                        />
                    ) : (
                        <CircleUserRound className="size-28" />
                    )}
                    <UpdateProfileImageModal asChild form={fileForm} onSubmit={onSendFile}>
                        <Button variant="outline" className="h-8 w-fit p-2">
                            Update image
                        </Button>
                    </UpdateProfileImageModal>
                    <Button
                        disabled={loadingSubmitDelete}
                        variant="destructive"
                        className="h-8 w-fit p-2"
                        onClick={() => {
                            onDeleteImage(refetchData)
                        }}
                    >
                        <LoadingIcon loading={loadingSubmitDelete} className="mr-2" />
                        Delete image
                    </Button>
                </Card>
                <div className="flex flex-col gap-2">
                    <div className="flex justify-between text-sm">
                        <p>Notifications</p>
                        <Switch checked={notifications} onCheckedChange={setNotifications} />
                    </div>
                </div>
                <Button
                    onClick={onSignOut}
                    className="bg-destructive/10 hover:bg-destructive/30 hover:text-accent-foreground text-destructive w-full"
                >
                    Sign out
                </Button>
            </div>
            <div className="flex w-full flex-col items-center gap-4">
                {!data?.emailVerified && (
                    <Alert variant="destructive" className="bg-red-50">
                        <AlertCircle className="h-4 w-4" />
                        <AlertTitle>Warning</AlertTitle>
                        <AlertDescription className="flex items-center gap-2">Your email is not verified.</AlertDescription>
                        <AlertDescription className="flex items-center gap-2">
                            Please verify it by clicking on the button.
                            <Button
                                disabled={loadingVerifying}
                                variant="none"
                                className="hover:bg-muted  border border-red-400"
                                size="sm"
                                onClick={() => {
                                    verifyEmail(refetchData)
                                }}
                            >
                                <LoadingIcon loading={loadingVerifying} className="mr-2" />
                                Verify email
                            </Button>
                        </AlertDescription>
                    </Alert>
                )}
                <Card className="flex w-full flex-col gap-3 p-5">
                    <span className="text-2xl font-bold">Personal information</span>
                    <Form {...profileForm}>
                        <form onSubmit={profileForm.handleSubmit(submitProfile)} className="space-y-5">
                            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                                <FormField
                                    control={profileForm.control}
                                    name="username"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Username</FormLabel>
                                            <FormControl>
                                                <Input placeholder="John Doe" {...field} />
                                            </FormControl>
                                            <FormDescription>This is your public display name.</FormDescription>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={profileForm.control}
                                    name="email"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Email</FormLabel>
                                            <FormControl>
                                                <Input placeholder="someemail@example.com" {...field} />
                                            </FormControl>
                                            <FormDescription>This is your public email.</FormDescription>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <Button disabled={loadingSubmitProfile} type="submit">
                                <LoadingIcon loading={loadingSubmitProfile} className="mr-2" />
                                Save
                            </Button>
                        </form>
                    </Form>
                </Card>

                <Card className="flex w-full flex-col gap-3 p-5">
                    <span className="text-2xl font-bold">Change password</span>
                    <span className="text-sm font-medium text-gray-500">
                        Password should include at least 8 characters, one uppercase letter, one lowercase letter, one number and
                        one special character
                    </span>
                    <Form {...passwordForm}>
                        <form onSubmit={passwordForm.handleSubmit(submitPassword)} className="space-y-5">
                            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                                <FormField
                                    control={passwordForm.control}
                                    name="newPassword"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>New password</FormLabel>
                                            <FormControl>
                                                <Input placeholder="********" type="password" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={passwordForm.control}
                                    name="confirmPassword"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Confirm password</FormLabel>
                                            <FormControl>
                                                <Input placeholder="********" type="password" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <Button type="submit" disabled={loadingSubmitPassword}>
                                <LoadingIcon loading={loadingSubmitPassword} className="mr-2" />
                                Save
                            </Button>
                        </form>
                    </Form>
                </Card>
            </div>
        </div>
    )
})

export default Profile
