/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingIcon } from 'components/loading-icon'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Separator } from 'components/ui/separator'
import { Skeleton } from 'components/ui/skeleton'
import { dotenv, ENV } from 'config/dotenv.config'
import { urlConfig } from 'config/url.config'
import dayjs from 'dayjs'
import { CirclePlus } from 'lucide-react'
import { useChatStore } from 'modules/chat/store/chat.store'
import { ChatType } from 'modules/chat/types/chat.type'
import { SidebarInstanceButton } from 'modules/instances/components/sidebar-instance-button'
import { useInstanceStore } from 'modules/instances/store/instances.store'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn'
import { z } from 'zod'

const createInstanceSchema = z.object({
    name: z.string().min(3, {
        message: 'Name must be at least 3 characters long',
    }),
})

interface SidebarProps extends React.ComponentProps<'div'> {}
export const Sidebar: React.FC<SidebarProps> = ({ className, children, ...props }) => {
    const root = useMemo(() => document.getElementById('root'), [])

    const auth = useAuthUser()()

    const isAllLoading = useChatStore(state => state.isAllLoading)

    const instances = useInstanceStore(state => state.instances)
    const setInstanceId = useInstanceStore(state => state.setInstanceId)
    const instanceId = useInstanceStore(state => state.instanceId)

    const navigate = useNavigate()

    
    

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

    const createInstance = useInstanceStore(state => state.createInstance)
    const updateInstance = useInstanceStore(state => state.updateInstance)

    const createInstanceForm = useForm<z.infer<typeof createInstanceSchema>>({
        resolver: zodResolver(createInstanceSchema),
        defaultValues: {
            name: '',
        },
    })

    async function onSubmit(values: z.infer<typeof createInstanceSchema>) {
        setIsButtonDisabled(true)
        const newInstance = await createInstance()

        await updateInstance({
            ...newInstance,
            name: values.name,
            ownerId: auth?.userOwnerId,
            colorSettings: JSON.stringify({
                contentBg: '#ffffff',
                headerCardBg: '#686D76',
                footerCardBg: '#686D76',
                clientMesBg: '#4B70F5',
            }),

            iconSettings: JSON.stringify({
                iconRadius: 'square',
                iconBgColorStyle: 'gradient',
                iconBg:'#1424ff',
                iconBgSecond: '#000000',
                iconSize: '65',
                iconPosition: 'right',
                iconPositionPadding: '10',
                iconBottomPadding: '10',
            }),
        })

        createInstanceForm.reset()
        setIsButtonDisabled(false)
    }

    const url = useMemo(() => {
        const url = new URL(`${window.location.protocol}//${window.location.host}/client?q=${instanceId}`)
        return url.toString()
    }, [instanceId])

    const scriptUrl = useMemo(() => {
        const url = new URL(`${dotenv.get(ENV.API_URL)}/chats/embedded/${instanceId}`)
        return url.toString()
    }, [instanceId])

    const sortChatsByLastMessage = useCallback<(a: ChatType, b: ChatType) => number>((a, b) => {
        if (!a.messages?.length) return -1
        if (!b.messages?.length) return 1
        const dateA = dayjs(a.messages?.at(-1)?.createdAt)
        const dateB = dayjs(b.messages?.at(-1)?.createdAt)
        return dateB.diff(dateA)
    }, [])

    useEffect(() => {
        if (root) {
            root.style.setProperty('--message', '221.2 83.2% 53.3%')
            root.style.setProperty('--chat-header', '0 0% 100%')
            root.style.setProperty('--chat-footer', '0 0% 100%')
            root.style.setProperty('--chat-content', '0, 0%, 100%, 0')
        }
    }, [])

    

    // skeleton loading
    if (isAllLoading) {
        return (
            <div {...props} className={cn('h-80 p-2', className)}>
                <Card className="no-scrollbar h-full w-full overflow-y-scroll">
                    <CardContent>
                        {new Array(20).fill(0).map((_, index) => (
                            <Fragment key={index}>
                                {index !== 0 && <Separator />}
                                <div className="mt-1 flex h-auto gap-2 px-4 py-2">
                                    <Skeleton className="h-10 w-12 rounded-full" />
                                    <div className="flex h-full w-full flex-col gap-2">
                                        <Skeleton className="h-5 w-full" />
                                        <Skeleton className="mt-2 h-3 w-full" />
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </CardContent>
                </Card>
            </div>
        )
    }

    return (
        <div {...props} className={cn('h-[93svh]  overflow-y-auto', className)}>
            <Card className="no-scrollbar h-full w-full overflow-y-scroll border-none selection:bg-slate-600 selection:text-gray-300">
                {/* command menu */}
                {/* <CommandMenu /> */}
              
                <CardHeader className="flex flex-row items-center justify-between px-4 py-2 pb-0">
                    <CardTitle className="text-xl uppercase">Websites</CardTitle>
                    <AlertDialog>
                        <AlertDialogTrigger asChild>
                            <Button
                                variant="ghost"
                                className={cn(
                                    'flex w-fit items-center gap-1 whitespace-nowrap px-0',
                                    'hover:bg-blue-50 hover:text-blue-600',
                                )}
                            >
                                Add new
                                <CirclePlus />
                            </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader className="flex flex-row items-center justify-between">
                                <AlertDialogTitle>Add new project</AlertDialogTitle>
                            </AlertDialogHeader>
                            <div>
                                <Form {...createInstanceForm}>
                                    <form
                                        onSubmit={createInstanceForm.handleSubmit(onSubmit)}
                                        className="flex w-full flex-col gap-5"
                                    >
                                        <FormField
                                            control={createInstanceForm.control}
                                            name="name"
                                            render={({ field }: { field: any }) => (
                                                <FormItem>
                                                    <FormLabel className="text-base font-light">Name</FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            className="border-zinc-300"
                                                            disabled={isButtonDisabled}
                                                            placeholder="VistaChat"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <AlertDialogFooter>
                                            <AlertDialogCancel>Close</AlertDialogCancel>
                                            <AlertDialogAction asChild>
                                                <Button disabled={isButtonDisabled} type="submit">
                                                    <LoadingIcon loading={isButtonDisabled} className="mr-2" />
                                                    Create project
                                                </Button>
                                            </AlertDialogAction>
                                        </AlertDialogFooter>
                                    </form>
                                </Form>
                            </div>
                        </AlertDialogContent>
                    </AlertDialog>
                </CardHeader>
                {instances.map((instance, index, array) => {
                    if (array.length === 0) {
                        return null
                    }
                    const { id } = instance
                    return (
                        <Fragment key={instance.id}>
                            <CardContent className="p-0 px-2">
                                {index !== 0 && <Separator className="mx-auto w-11/12" />}
                                
                                <SidebarInstanceButton
                                   
                                    instance={instance}
                                    instances={array}
                                    className="my-1"
                                    active={id === instanceId}
                                    onClick={() => {
                                     
                                        navigate(urlConfig.pages.instance.replace(':id', id))
                                        setInstanceId(id)
                                    }}
                                />
                            </CardContent>
                        </Fragment>
                    )
                })}
                
                {/* </>
                )} */}
            </Card>
        </div>
    )
}
Sidebar.displayName = Sidebar.name
