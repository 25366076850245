import { memo } from 'react'
import { cn } from 'utils/cn'

interface SidebarBadgeProps extends React.ComponentProps<'span'> {
    show?: boolean
}
export const SidebarBadge: React.FC<SidebarBadgeProps> = memo(({ show = false, className, ...props }) => {
    if (!show) {
        return null
    }

    return (
        <span {...props} className={cn('animate-in zoom-in-50 fade-in-0 absolute left-1 top-2 flex h-3 w-3', className)}>
            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75" />
            <span className="relative inline-flex h-3 w-3 rounded-full bg-sky-500" />
        </span>
    )
})
SidebarBadge.displayName = SidebarBadge.name
