import { toast } from 'components/ui/use-toast'
import { urlConfig } from 'config/url.config'
import { ChatType } from 'modules/chat/types/chat.type'
import { useEffect, useState } from 'react'

export const useGetInstanceChats = (instanceId: string | undefined) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<ChatType[] | null>(null)

    const fetchData = async (id: string) => {
        try {
            const res = await fetch(`${urlConfig.API_URL}${urlConfig.api.instanceChats}/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!res.ok) {
                throw new Error('Failed to fetch users')
            }

            const responseData = (await res.json()) as { message: string; data: ChatType[] }

            if (!responseData.data) {
                throw new Error('Failed to fetch chats data')
            }

            setData(responseData.data)
        } catch (error) {
            toast({
                title: 'Failed to fetch chats',
                description: 'An error occurred while fetching chats. Please try again later.',
                variant: 'destructive',
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (instanceId) {
            fetchData(instanceId)
        }
    }, [instanceId])

    const refetchData = () => {
        setLoading(true)
        if (instanceId) {
            fetchData(instanceId)
        }
    }

    return { loading, data, refetchData }
}
