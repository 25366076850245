import { Table } from '@tanstack/react-table'
import { Button } from 'components/ui/button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from 'components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { defaultIconProps } from 'config/constants'
import { Check, Settings2 } from 'lucide-react'
import { useState } from 'react'
import { cn } from 'utils/cn'

interface DataTableViewProps<TData> {
    table: Table<TData>
}
export const DataTableView = <TData,>({ table }: DataTableViewProps<TData>) => {
    const [open, setOpen] = useState<boolean>(false)

    // const onClear = useCallback<() => void>(() => {}, [])

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button variant="outline" size="sm" className="ml-auto hidden h-8 lg:flex">
                    <Settings2 {...defaultIconProps} className="mr-2 h-4 w-4" />
                    View
                </Button>
            </PopoverTrigger>
            <PopoverContent align="end" className="w-52 p-0">
                <Command>
                    <CommandInput placeholder="Search columns..." className="h-9" />
                    <CommandEmpty>No columns found</CommandEmpty>
                    <CommandList>
                        {/* <CommandItem onSelect={onClear} className="flex cursor-pointer items-center gap-1 capitalize">
                            <Check {...defaultIconProps} className="ml-1 mt-0.5 h-5 opacity-0 transition-all duration-300" />
                            Reset
                        </CommandItem> */}
                        <CommandSeparator />
                        <CommandGroup className="h-full overflow-y-scroll">
                            {table
                                .getAllColumns()
                                .filter(column => typeof column.accessorFn !== 'undefined' && column.getCanHide())
                                .map(column => {
                                    return (
                                        <CommandItem
                                            key={column.id}
                                            onSelect={() => column.toggleVisibility()}
                                            className="flex cursor-pointer items-center gap-1 capitalize"
                                        >
                                            <Check
                                                {...defaultIconProps}
                                                className={cn(
                                                    'mt-0.5 h-5 transition-all duration-300',
                                                    !column.getIsVisible() && 'opacity-0',
                                                )}
                                            />
                                            {column.id}
                                        </CommandItem>
                                    )
                                })}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
