interface AdminAvatarProps {
    image: string
    name: string
}

const AdminAvatar: React.FC<AdminAvatarProps> = ({ image, name, ...props }) => {
    return (
        <>
            {image ? (
                <img src={image} className="h-10 w-10 rounded-full border object-cover object-center" decoding="async" {...props} />
            ) : (
                <div className="size-10 flex items-center justify-center rounded-full border bg-slate-50 text-xl font-bold">
                    {name?.[0]}
                </div>
            )}
        </>
    )
}
export default AdminAvatar
