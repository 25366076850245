import { CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Separator } from 'components/ui/separator'
import { ChatType, ChatTypeFieldsEnum } from 'modules/chat/types/chat.type'
import { SidebarButton } from 'modules/sidebar/components/sidebar-button'
import { Fragment } from 'react/jsx-runtime'
import { InstanceType } from '../types/instance.type'
import { useEffect, useMemo } from 'react'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { firebaseCollections, firebaseFirestore } from 'config/firebase.config'

interface InstanceChatsProps {
    instance: InstanceType
    chats: ChatType[]
    archivedChats: ChatType[]
    bannedChats: ChatType[]
    sortChatsByLastMessage: (a: ChatType, b: ChatType) => number
}

const InstanceChats: React.FC<InstanceChatsProps> = ({
    instance,
    chats,
    archivedChats,
    bannedChats,
    sortChatsByLastMessage,
    ...props
}) => {
    const root = useMemo(() => document.getElementById('root'), [])

    useEffect(() => {
        if (root) {
            root.style.setProperty('--message', '221.2 83.2% 53.3%')
            root.style.setProperty('--chat-header', '0 0% 100%')
            root.style.setProperty('--chat-footer', '0 0% 100%')
            root.style.setProperty('--chat-content', '0, 0%, 100%, 0')
        }
    }, [root])

    return (
        <div>
            {Object.entries({
                'Your chats': chats,
                Archived: archivedChats,
                Banned: bannedChats,
            }).map(([key, value]) => {
                if (value.length === 0) {
                    return null
                }
                return (
                    <Fragment key={key}>
                        <CardHeader className="p-0 pb-2 pl-2">
                            <CardTitle className="text-xl">{key}</CardTitle>
                        </CardHeader>
                        <CardContent>
                            {/* @ts-expect-error toSorted - new array method */}
                            {value?.toSorted(sortChatsByLastMessage)?.map((chat, index) => (
                                <Fragment key={chat.id}>
                                    {index !== 0 && <Separator />}
                                    <SidebarButton
                                        archived={key.toLowerCase().includes('archived')}
                                        chat={chat}
                                        className="my-1"
                                    />
                                </Fragment>
                            ))}
                        </CardContent>
                    </Fragment>
                )
            })}
        </div>
    )
}
export default InstanceChats
