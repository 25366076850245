export interface BannedUserType {
    id: string
    //
    ip: string
    reason: string
    bannedBy: string
    bannedAt: string
}

export enum BannedUserFieldsEnum {
    IP = 'ip',
}

export interface UserType {
    id: string
    //
    name: string
    email: string
    role: string
    userOwnerId: string
    password?: string
    registered_ip?: string
    registered_date?: string
    registered_location?: string
}
