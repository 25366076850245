import { dbInstances } from 'config/firebase.config'
import { where } from 'firebase/firestore'
import _ from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { InstanceType } from '../types/instance.type'

interface UseInstanceStore {
    instances: InstanceType[]

    instanceId: InstanceType['id']
    isAllLoading: boolean
    isIdLoading: boolean
    //
    setInstanceId: (id: InstanceType['id']) => void
    //
    fetchInstances: (id: string) => Promise<void>
    fetchInstanceById: (id: string) => Promise<InstanceType | undefined>
    createInstance: () => Promise<InstanceType>
    updateInstance: (instance: InstanceType) => Promise<InstanceType>
    deleteInstance: (id: InstanceType['id']) => Promise<void>
    //
    setAllInstances: (instances: InstanceType[]) => void
    setInstanceById: (instance: InstanceType) => void
    //
}

export const useInstanceStore = create<
    UseInstanceStore,
    [['zustand/devtools', UseInstanceStore], ['zustand/immer', UseInstanceStore]]
>(
    devtools(
        immer((set, get) => ({
            instances: [] as InstanceType[],

            instanceId: '',
            isAllLoading: false as boolean,
            isIdLoading: false as boolean,

            //

            setInstanceId: id => {
                set(state => {
                    state.instanceId = id
                })
            },

            //

            fetchInstances: async id => {
                set(state => {
                    state.isAllLoading = true
                })
                try {
                    localStorage.clear()
                    const setAllInstances = get().setAllInstances
                    const allInstances = await dbInstances.query(where('ownerId', '==', id))
                    setAllInstances(allInstances)
                } catch (error) {
                    console.error(error)
                } finally {
                    set(state => {
                        state.isAllLoading = false
                    })
                }
            },

            //

            fetchInstanceById: async id => {
                set(state => {
                    state.isIdLoading = true
                })
                try {
                    const fetchedInstance = await dbInstances.getById(id)
                    if (!fetchedInstance) {
                        throw new Error('Chat not found')
                    }
                    set(state => {
                        state.instances = state.instances.map(stateChat => {
                            if (stateChat.id !== id) {
                                return stateChat
                            }
                            return fetchedInstance
                        })
                    })
                    return fetchedInstance
                } catch (error) {
                    console.error(error)
                } finally {
                    set(state => {
                        state.isIdLoading = false
                    })
                }
            },

            //

            createInstance: async () => {
                const newInstance = await dbInstances.create({
                    name: '',
                    ownerId: '',
                    colorSettings: '',
                    iconSettings: '',
                })
                set(state => {
                    state.instances = [...state.instances, newInstance]
                })
                return newInstance
            },

            //

            updateInstance: async instance => {
                const setInstanceById = get().setInstanceById
                setInstanceById(instance)
                await dbInstances.update(instance)
                return instance
            },

            //

            deleteInstance: async id => {
                const state = get()
                const instance = [...state.instances].find(instance => instance.id === id)
                set(state => {
                    state.instances = state.instances.filter(instance => instance.id !== id)
                })
                await dbInstances.delete(id)
            },

            //

            setAllInstances: instances => {
                const instancesWithoutDuplicates = _.uniqBy([...get().instances, ...instances], data => data.id)
                set(state => {
                    state.instances = instancesWithoutDuplicates
                })
            },

            //

            setInstanceById: instance => {
                set(state => {
                    state.instances = state.instances.map(stateInstance => {
                        if (stateInstance.id !== instance.id) {
                            return stateInstance
                        }
                        return instance
                    })
                })
            },
        })),
        {
            name: 'instances',
        },
    ),
)
