import { toast } from 'components/ui/use-toast'
import { urlConfig } from 'config/url.config'
import { useState } from 'react'
import { z } from 'zod'
import { profileSchema } from '../schemas/profile.schema'
import { UserProfile } from './use-get-profile'

export const useUpdateProfileInfo = (data: UserProfile | null) => {
    const [loadingSubmitProfile, setLoading] = useState<boolean>(false)

    async function onSubmitProfile(values: z.infer<typeof profileSchema>, cb: () => void) {
        setLoading(true)

        const resPromise = await fetch(`${urlConfig.API_URL}${urlConfig.api.adminUsers}/${data?.uid}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...(data?.email !== values.email && { email: values.email }),
                ...(data?.name !== values.username && { name: values.username }),
            }),
        })
        if (!resPromise.ok) {
            toast({
                title: 'Failed to update user',
                description: 'Error occurred while updating user. Please try again later',
                variant: 'destructive',
            })
            setLoading(false)
            return
        }

        toast({
            title: 'User updated',
            description: 'User information updated successfully',
        })

        setLoading(false)
        cb?.()
    }

    return { loadingSubmitProfile, onSubmitProfile }
}
