import { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import dayjs from 'dayjs'
import { AdminSchemaType } from './admin.schema'
import { AdminActionsCell } from './cells/admin-actions'
import AdminAvatar from './components/admin-avatar'

export const adminUsersColumns: ColumnDef<AdminSchemaType>[] = [
    {
        accessorKey: 'uid',
        header: 'ID',
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'image',
        header: 'Avatar',
        cell: ({ row }) => <AdminAvatar image={row.getValue('image')} name={row.getValue('name')} />,
    },
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        cell: ({ row }) => <span>{row.getValue('name')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'email',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
        cell: ({ row }) => <span>{row.getValue('email')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'role',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Role" />,
        cell: ({ row }) => <span className="capitalize">{row.getValue('role')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'instancesAmount',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Instances" />,
        cell: ({ row }) => <span>{row.getValue('instancesAmount')}</span>,
    },
    {
        accessorKey: 'ownerUsers',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Users" />,
        cell: ({ row }) => <span>{row.getValue('ownerUsers')}</span>,
    },
    {
        accessorKey: 'lastSignIn',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Last Sign In" />,
        cell: ({ row }) => <span className="whitespace-nowrap">{row.getValue('lastSignIn')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'createdBy',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Created by" />,
        cell: ({ row }) => <span>{row.getValue('createdBy')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'registered_date',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Registered date" />,
        cell: ({ row }) => {
            return (
                <span className="whitespace-nowrap">
                    {row.getValue('registered_date') === '-'
                        ? row.getValue('registered_date')
                        : dayjs(row.getValue('registered_date')).format('ddd, DD MMM YYYY HH:mm:ss [GMT]')}
                </span>
            )
        },
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'registered_ip',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Registered ip" />,
        cell: ({ row }) => <span>{row.getValue('registered_ip')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'registered_location',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Registered location" />,
        cell: ({ row }) => {
            const location =
                row.getValue('registered_location') === '-'
                    ? '-'
                    : JSON.parse(row.getValue('registered_location')).city +
                      ', ' +
                      JSON.parse(row.getValue('registered_location')).country
            return <span>{location}</span>
        },
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'status',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
        cell: ({ row }) => (
            <AdminActionsCell
                id={row.getValue('uid')}
                refetchData={row.original.refetch}
                email={row.getValue('email')}
                name={row.getValue('name')}
                role={row.getValue('role')}
            />
        ),
        sortingFn: 'text',
        filterFn: 'weakEquals',
    },
]
