import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import { ScrollBar } from 'components/ui/scroll-area'
import { TooltipProvider } from 'components/ui/tooltip'
import { ChatDateSeparator } from 'modules/chat/components/chat-date-separator'
import { ChatType } from 'modules/chat/types/chat.type'
import { MessageRole } from 'modules/chat/types/message.type'
import { Message } from 'modules/message/components/message'
import { Fragment, forwardRef, memo, useMemo } from 'react'
import { cn } from 'utils/cn'

interface ChatContentProps extends React.ComponentProps<typeof ScrollAreaPrimitive.Root> {
    chat?: ChatType
    role: MessageRole
    isLoading?: boolean
    textMessageColor?: string
}
export const ChatContent = memo(
    forwardRef<React.ComponentRef<'div'>, ChatContentProps>(
        ({ chat, role, isLoading, className, textMessageColor, children, ...props }, ref) => {
            const messages = useMemo(() => (isLoading ? new Array(20).fill(0) : chat?.messages), [chat?.messages, isLoading])
            return (
                <ScrollAreaPrimitive.Root
                    {...props}
                    className={cn('bg-chatcontent h-screen w-full overflow-hidden px-6 pb-14 pt-16', className)}
                >
                    <ScrollAreaPrimitive.Viewport className="flex h-full w-full flex-col-reverse rounded-[inherit]">
                        <div className="pt-3" />
                        <TooltipProvider>
                            {messages?.map((message, index, array) => (
                                <Fragment key={isLoading ? index : message.createdAt}>
                                    <ChatDateSeparator
                                        currentMessage={message}
                                        prevMessage={array?.[index - 1]}
                                        isLoading={isLoading}
                                    />
                                    <Message
                                        chat={chat}
                                        message={message}
                                        right={isLoading ? index % 2 === 0 : message.senderRole === role}
                                        isLoading={isLoading}
                                        className="mt-1"
                                        textColor={textMessageColor}
                                    />
                                </Fragment>
                            ))}
                        </TooltipProvider>
                        <div ref={ref} className="pt-3" />
                    </ScrollAreaPrimitive.Viewport>
                    <ScrollBar className="flex flex-col-reverse opacity-0" />
                    <ScrollAreaPrimitive.Corner />
                </ScrollAreaPrimitive.Root>
            )
        },
    ),
)
ChatContent.displayName = ChatContent.name
