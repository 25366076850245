import { toast } from 'components/ui/use-toast'
import { deleteImageFromStorage } from 'config/firebase.config'
import { toastConfig } from 'config/toast.config'
import { urlConfig } from 'config/url.config'
import { useState } from 'react'
import { UserProfile } from './use-get-profile'

export const useDeleteProfileImage = (data: UserProfile | null) => {
    const [loadingSubmitDelete, setLoading] = useState<boolean>(false)

    const onDeleteImage = async (cb: () => void) => {
        try {
            setLoading(true)
            if (!data?.image) {
                toast({
                    title: 'Failed to delete image',
                    description: 'No image found',
                    variant: 'destructive',
                })
                return
            }

            await deleteImageFromStorage(data?.image)

            const resPromise = await fetch(`${urlConfig.API_URL}${urlConfig.api.profile}/${data?.uid}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (!resPromise.ok) {
                toast({
                    title: 'Failed to update user',
                    description: 'Error occurred while updating user. Please try again later',
                    variant: 'destructive',
                })
                return
            }

            toast({
                title: 'User updated',
                description: 'Image deleted successfully',
            })
            cb?.()
        } catch (error) {
            console.error(error)
            toastConfig.unknownError()
        } finally {
            setLoading(false)
        }
    }

    return { loadingSubmitDelete, onDeleteImage }
}
