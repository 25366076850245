import { Marker } from '@react-google-maps/api'
import { SVGProps, memo } from 'react'

import { cn } from 'utils/cn'

interface MapMarkerProps extends Partial<SVGProps<SVGSVGElement>> {
    lat: number
    lng: number
}
export const MapMarker: React.FC<MapMarkerProps> = memo(({ lat, lng, className, ...props }) => {
    const MarkerRedIcon: google.maps.Symbol = {
        path: "M19 53.25C19 53.25 0.625 29.2838 0.625 19.125C0.625 16.712 1.10028 14.3225 2.02371 12.0932C2.94714 9.86383 4.30064 7.83819 6.00691 6.13191C7.71319 4.42564 9.73883 3.07214 11.9682 2.14871C14.1975 1.22528 16.587 0.75 19 0.75C21.413 0.75 23.8025 1.22528 26.0318 2.14871C28.2612 3.07214 30.2868 4.42564 31.9931 6.13191C33.6994 7.83819 35.0529 9.86383 35.9763 12.0932C36.8997 14.3225 37.375 16.712 37.375 19.125C37.375 29.2838 19 53.25 19 53.25ZM19 24.375C20.3924 24.375 21.7277 23.8219 22.7123 22.8373C23.6969 21.8527 24.25 20.5174 24.25 19.125C24.25 17.7326 23.6969 16.3973 22.7123 15.4127C21.7277 14.4281 20.3924 13.875 19 13.875C17.6076 13.875 16.2723 14.4281 15.2877 15.4127C14.3031 16.3973 13.75 17.7326 13.75 19.125C13.75 20.5174 14.3031 21.8527 15.2877 22.8373C16.2723 23.8219 17.6076 24.375 19 24.375Z",
        fillColor: "#AA221F",
        fillOpacity: 1,
        scale: 1,
        ...(typeof window !== "undefined" && window.google?.maps?.Point
          ? { anchor: new window.google.maps.Point(19, 54) }
          : {}),
      };
    
    return (
        <Marker
                options={{
                  clickable: true,
                  icon: MarkerRedIcon,
                  zIndex: 1500,
                }}
               
                position={{
                  lat: lat,
                  lng: lng,
                }}

              />
    )
})
MapMarker.displayName = MapMarker.name
