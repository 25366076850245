/* eslint-disable @typescript-eslint/no-explicit-any */
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingIcon } from 'components/loading-icon'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { urlConfig } from 'config/url.config'
import { useThemeStore } from 'modules/theme/store/theme.store'
import { useLogin } from 'modules/user/hooks/use-login'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn'
import { z } from 'zod'

const passwordTypes = {
    password: 'password',
    text: 'text',
}

const loginSchema = z.object({
    email: z
        .string()
        .email({
            message: 'Wrong email address',
        })
        .min(5),
    password: z.string().min(5, {
        message: 'Please enter correct password!',
    }),
})

const Login = () => {
    const theme = useThemeStore(state => state.theme)
    const [passwordType, setPasswordType] = useState<string>(passwordTypes.password)
    const { isButtonDisabled, loginWithEmailAndPassword } = useLogin()

    const navigate = useNavigate()

    const loginForm = useForm<z.infer<typeof loginSchema>>({
        resolver: zodResolver(loginSchema),
        defaultValues: {
            email: '',
            password: '',
        },
    })

    function onSubmit(values: z.infer<typeof loginSchema>) {
        function navigateTo() {
            navigate(urlConfig.pages.main)
        }

        loginWithEmailAndPassword(values.email, values.password, navigateTo)
    }

    return (
        <div className="flex w-screen flex-col items-center justify-center mt-20 ">
            <div className={cn('w-full flex flex-col gap-3 rounded-xl p-5 md:w-1/3', theme === 'dark' ? ' bg-zinc-800' : 'bg-white')}>
                <span className="font-serif text-4xl">Login</span>
                <Form {...loginForm}>
                    <form onSubmit={loginForm.handleSubmit(onSubmit)} className="flex w-full flex-col gap-5">
                        <FormField
                            control={loginForm.control}
                            name="email"
                            render={({ field }: { field: any }) => (
                                <FormItem>
                                    <FormLabel className="text-lg">Email</FormLabel>
                                    <FormControl>
                                        <Input className="border-zinc-900" placeholder="email@doe.com" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={loginForm.control}
                            name="password"
                            render={({ field }: { field: any }) => (
                                <FormItem>
                                    <FormLabel className="flex justify-between text-lg">
                                        <span>Password</span>
                                        <span
                                            className="cursor-pointer text-blue-600"
                                            onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
                                        >
                                            Show
                                        </span>
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            id="password"
                                            className="border-zinc-900"
                                            placeholder="*****"
                                            type={passwordType}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Link to="/reset" className="w-fit cursor-pointer text-lg text-blue-600">
                            Forgot password?
                        </Link>
                        <Button disabled={isButtonDisabled} type="submit" className="rounded-full py-7 text-xl uppercase">
                            <LoadingIcon loading={isButtonDisabled} className="mr-2" />
                            Login
                        </Button>
                        <div className="flex justify-center gap-1 text-xl">
                            <span>Don’t an account?</span>
                            <Link to="/register" className="cursor-pointer text-blue-600">
                                Sign up
                            </Link>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    )
}
export default Login
