export function hexToHSL(hex: string): string {
    // Convert hex to RGB first
    let r = 0,
        g = 0,
        b = 0
    if (hex?.length === 7) {
        r = parseInt(hex.slice(1, 3), 16)
        g = parseInt(hex.slice(3, 5), 16)
        b = parseInt(hex.slice(5, 7), 16)
    }

    // Convert RGB to HSL
    const rNormalized = r / 255,
        gNormalized = g / 255,
        bNormalized = b / 255
    const max = Math.max(rNormalized, gNormalized, bNormalized),
        min = Math.min(rNormalized, gNormalized, bNormalized)
    let h = 0,
        s = 0
    const l = (max + min) / 2

    if (max !== min) {
        const d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
            case rNormalized:
                h = ((gNormalized - bNormalized) / d + (gNormalized < bNormalized ? 6 : 0)) / 6
                break
            case gNormalized:
                h = ((bNormalized - rNormalized) / d + 2) / 6
                break
            case bNormalized:
                h = ((rNormalized - gNormalized) / d + 4) / 6
                break
        }
    }

    // Convert HSL to string
    const hDeg = Math.round(h * 360)
    const sPercent = Math.round(s * 100)
    const lPercent = Math.round(l * 100)

    return `${hDeg}, ${sPercent}%, ${lPercent}%`
}
